const NRC_STATUS = {
  SCHEDULED: 'scheduled',
  DECLINED: 'declined',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

const NRC_LOGIN_PROMOTION_KEY = 'login-feature-nrc';

const PAYER_OPTIONS = {
  YOURSELF: 'yourself',
  SOMEONE_ELSE: 'someoneElse',
};

export { NRC_STATUS, NRC_LOGIN_PROMOTION_KEY, PAYER_OPTIONS };
