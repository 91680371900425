export const ELIGIBILITY_STATUS = {
  ELIGIBLE: 'eligible',
  INELIGIBLE: 'ineligible',
};

export const DEFAULT_ELIGIBILITY = {
  status: ELIGIBILITY_STATUS.INELIGIBLE,
  reason: 'unknown',
};

export const INELIGIBILITY_REASON = {
  FLEX_KYB_NOT_APPROVED: 'flex_kyb_not_approved',
  KYB_NOT_APPROVED: 'kyb_not_approved',
  UNKNOWN: 'unknown',
};
