export const cardListeners = [
  'card.flash_created',
  'card.virtual_created',
  'card.physical_ordered_digital_first',
  'card.advertising_created',
];

export const TRANSFER_EVENTS = {
  CREATED: 'transfer.created',
  SETTLED: 'transfer.successful_settled',
  INSTANT_SETTLED: 'transfer.instant_settled_on_network',
  INSTANT_DECLINED: 'transfer.instant_declined',
  INSTANT_TIMEOUT: 'transfer.instant_timeout',
};

export const SOLUTION_INSTANCE_EVENTS = {
  CREATED: 'solution_instance.created',
  ENABLED: 'solution_instance.enabled',
  FAILED: 'solution_instance.failed',
  DELETED: 'solution_instance.deleted',
};

export const TRAY_WIZARD_EVENTS = {
  CANCEL: 'tray.configPopup.cancel',
  FINISH: 'tray.configPopup.finish',
};

export const REQUEST_EVENTS = {
  EMPLOYEE_CREATED: 'request.created',
  EMPLOYEE_CANCELED: 'request.canceled',
  EMPLOYEE_APPROVED: 'request_transfer.approved_to_requester',
  EMPLOYEE_SDD_APPROVED: 'request_multi_direct_debit_collection.approved_to_requester',
  ADMIN_SDD_APPROVED: 'request_multi_direct_debit_collection.approved_to_admin',
  ADMIN_DECLINED: 'request.declined_to_admin',
  EMPLOYEE_DECLINED: 'request.declined_to_requester',
  ADMIN_APPROVED: 'request_transfer.approved_to_requester',
  ADMIN_APPROVED_MULTI_TRANSFER: 'request_transfer.approved_to_requester',
};

export const SUPPLIER_INVOICE_EVENTS = {
  ANALYZED: 'supplier_invoices.analyzed',
  REQUEST_CREATED: 'supplier_invoices.pending',
  REQUEST_CANCELED: 'supplier_invoices.request_transfer_canceled',
  REQUEST_DECLINED: 'supplier_invoices.request_transfer_declined',
  SCHEDULED: 'supplier_invoices.scheduled',
};

export const RECEIVABLE_INVOICES_EVENTS = {
  UNPAID: 'receivable_invoices.unpaid',
};
