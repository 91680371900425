export const CARD_STATUSES_COLORS = {
  pending: 'orange',
  order_canceled: 'red',
  live: 'green',
  paused: 'orange',
  stolen: 'red',
  lost: 'red',
  pin_blocked: 'orange',
  deleted: 'red',
  pre_expired: 'red',
  expired: 'red',
};

export const CARD_STATUSES = {
  PENDING: 'pending',
  ORDER_CANCELED: 'order_canceled',
  LIVE: 'live',
  PAUSED: 'paused',
  STOLEN: 'stolen',
  LOST: 'lost',
  PIN_BLOCKED: 'pin_blocked',
  DELETED: 'deleted',
  PRE_EXPIRED: 'pre_expired',
  EXPIRED: 'expired',
};

const {
  PENDING,
  LIVE,
  PAUSED,
  STOLEN,
  LOST,
  PIN_BLOCKED,
  DELETED,
  PRE_EXPIRED,
  EXPIRED,
  ORDER_CANCELED,
} = CARD_STATUSES;

export const CARD_STATUSES_ACTIVE = [LIVE, PAUSED, PIN_BLOCKED];

export const CARD_STATUSES_USABLE = [LIVE, PENDING, PIN_BLOCKED, PAUSED];

export const CARD_STATUSES_ARCHIVED = [DELETED, EXPIRED, LOST, PRE_EXPIRED, STOLEN];

export const CARD_STATUSES_INSURED = [LIVE, LOST, PAUSED, PIN_BLOCKED, STOLEN];

export const CARD_STATUSES_UNINSURED = [DELETED, EXPIRED, ORDER_CANCELED, PENDING, PRE_EXPIRED];

export const CARD_STATUSES_GHOST = [DELETED, PRE_EXPIRED, EXPIRED, LOST, STOLEN, ORDER_CANCELED];

export const CARD_NAMES = {
  plus: 'Plus',
  standard: 'One',
  metal: 'X',
};

export const CARD_LOCK_REASONS = {
  LOCK: 'lock',
  LOST: 'lost',
  STOLEN: 'stolen',
};

export const CARD_LEVELS = {
  ADVERTISING: 'advertising',
  FLASH: 'flash',
  METAL: 'metal',
  PLUS: 'plus',
  STANDARD: 'standard',
  VIRTUAL: 'virtual',
};

export const CARD_DESIGNS = {
  ADVERTISING_DEFAULT_2023: 'advertising.default.2023',
  FLASH_DEFAULT_2021: 'flash.default.2021',
  METAL_GRAPHITE_BLACK_2019: 'metal.graphite.black.2019',
  METAL_MINERAL_GRAY_2024: 'metal.mineral.gray.2024',
  METAL_SAND_GOLD_2024: 'metal.sand.gold.2024',
  PLUS_BLACK_2023: 'plus.black.2023',
  PLUS_RECYCLED_PLASTIC_SILVER_2023: 'plus.recycled.plastic.silver.2023',
  PLUS_RECYCLED_PLASTIC_BLACK_2023: 'plus.recycled.plastic.black.2023',
  PLUS_RECYCLED_PLASTIC_LILAC_2023: 'plus.recycled.plastic.lilac.2023',
  STANDARD_RECYCLED_PLASTIC_2023: 'standard.recycled.plastic.2023',
  VIRTUAL_DEFAULT_2017: 'virtual.default.2017',
};

export const CUSTOMIZABLE_CARDS_LEVELS = [CARD_LEVELS.PLUS, CARD_LEVELS.METAL];

export const CARD_PRINT_TYPES = {
  EMBOSSED: 'embossed',
  PRINT: 'print',
};

export const CARD_LEVELS_TRACKING = {
  [CARD_LEVELS.STANDARD]: 'one',
  [CARD_LEVELS.PLUS]: 'plus',
  [CARD_LEVELS.METAL]: 'x',
  [CARD_LEVELS.VIRTUAL]: 'virtual',
  [CARD_LEVELS.FLASH]: 'flash',
  [CARD_LEVELS.ADVERTISING]: 'advertising',
};

export const CARD_TYPES = {
  PHYSICALS: [CARD_LEVELS.STANDARD, CARD_LEVELS.PLUS, CARD_LEVELS.METAL],
  VIRTUALS: [CARD_LEVELS.ADVERTISING, CARD_LEVELS.FLASH, CARD_LEVELS.VIRTUAL],
};

export const CARD_DELIVERY_ESTIMATE_DAYS = {
  [CARD_LEVELS.STANDARD]: 5,
  [CARD_LEVELS.PLUS]: 5,
  [CARD_LEVELS.METAL]: 3,
};

export const CARD_FLOWS_TRACKING_ORIGINS = {
  CARDS_PAGE: 'cards_page',
  CREATION_ONBOARDING: 'onboarding',
  CREATION_REGULAR: 'regular',
  USER_INVITATION: 'invitation_user-access',
  USER_INVITATION_CARD_SELECTION: 'invitation_card-selection',
  USER_INVITATION_SUCCESS_GET_STARTED: 'invitation-success_get-started',
  RENEWAL: 'renewal',
  UPSELL: 'upsell',
};

export const ORIGIN = {
  CARD_REORDER: 'reorder',
  CREATION_REGULAR: 'regular',
  MEMBER_INVITATION: 'invitee',
};

export const TRACKING_ORIGIN = {
  [ORIGIN.MEMBER_INVITATION]: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION,
  [ORIGIN.CREATION_REGULAR]: CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR,
};

export const FEATURE_FLAGS = {
  ABROAD_OPTION: 'cardAbroadOption',
  CONCIERGE: 'concierge',
  LOUNGE: 'lounge',
};

export const CARD_LIFESPAN_LIMITS = {
  default: 100,
  min: 5,
};

export const PLUS_CARD_WITHDRAWALS_INCLUDED = 5;

export const MIN_WITHDRAWAL_LIMIT = 10;

export const CARD_WITHDRAW_FEE = 2;

export const CARD_FEES = {
  standard: { withdrawFees: 0.01, foreignFees: 0.02 },
  plus: { withdrawFees: 0.01, foreignFees: 0.01 },
  metal: { withdrawFees: 0.01, foreignFees: 0 },
};

export const CARDS_LIMITS_ROUTES = [
  'payment-limits',
  'withdrawal-limits',
  'payment-lifespan-limit',
];

// This is the set of options to format cards costs with intl.formatNumber
export const FORMAT_PRICE_OPTIONS = {
  minimumFractionDigits: 0,
  currency: 'EUR',
  style: 'currency',
};

export const UPSELL_BANNERS_COOKIE_IDS = {
  BANNER_PAYMENT: 'qonto_upsell-banner-payment',
  BANNER_WITHDRAWAL: 'qonto_upsell-banner-withdrawal',
};

export const COMPARE_TAB_ID = 'compare';

export const CARD_OPTIONS_CODES = {
  [CARD_LEVELS.STANDARD]: 'physical_card',
  [CARD_LEVELS.PLUS]: 'physical_card_plus',
  [CARD_LEVELS.METAL]: 'physical_card_metal',
  [CARD_LEVELS.VIRTUAL]: 'virtual_card',
  [CARD_LEVELS.FLASH]: 'flash_card',
  [CARD_LEVELS.ADVERTISING]: 'advertising_card',
};
