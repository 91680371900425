import { variation } from 'ember-launch-darkly';

export const ENABLED_LEGAL_COUNTRIES = ['FR'];

export const getEnabledLegalCountries = () => {
  return [
    ...ENABLED_LEGAL_COUNTRIES,
    ...(variation('feature--boolean-insurance-hub-de') ? ['DE'] : []),
  ];
};

export const ENABLED_DE_NACE_CODES = [
  '4120',
  '4321',
  '4329',
  '4333',
  '4511',
  '4520',
  '4791',
  '4941',
  '5229',
  '5320',
  '5610',
  '6201',
  '6202',
  '6209',
  '6420',
  '6622',
  '6831',
  '6832',
  '6920',
  '7010',
  '7022',
  '7311',
  '7711',
  '7810',
  '8110',
  '8121',
  '8130',
  '8559',
  '9602',
];

export const PROVIDERS_MAP = {
  DE: 'Signal Iduna',
  FR: 'Stello',
};

export const CATEGORIES = {
  BUSINESSES: 'businesses',
  EMPLOYEES: 'employees',
  OWNERS: 'owners',
};

export const PRODUCT_ROUTE = 'insurance-hub.insurances.product';
export const INDEX_ROUTE = 'insurance-hub.insurances.index';

export const INSURANCE_CONTRACT_STATUS = {
  ACTION_REQUIRED: 'action_required',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  PENDING_OTHERS: 'pending_others',
  PENDING_PAYMENT: 'pending_payment',
};

export const INSURANCE_CONTRACT_INTERNAL_STATUS = {
  ACTION_REQUIRED: 'action_required',
  ACTIVE: 'active',
  SCHEDULED: 'scheduled',
  EXPIRED: 'expired',
};

export const INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER = [
  INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  INSURANCE_CONTRACT_INTERNAL_STATUS.ACTIVE,
  INSURANCE_CONTRACT_INTERNAL_STATUS.SCHEDULED,
  INSURANCE_CONTRACT_INTERNAL_STATUS.EXPIRED,
];

export const INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING = {
  [INSURANCE_CONTRACT_STATUS.ACTION_REQUIRED]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  [INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  [INSURANCE_CONTRACT_STATUS.ACTIVE]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTIVE,
  [INSURANCE_CONTRACT_STATUS.PENDING_OTHERS]: INSURANCE_CONTRACT_INTERNAL_STATUS.SCHEDULED,
  [INSURANCE_CONTRACT_STATUS.EXPIRED]: INSURANCE_CONTRACT_INTERNAL_STATUS.EXPIRED,
};

export const INSURANCE_CONTRACT_FREQUENCY = {
  MONTHLY: 'month',
  QUARTERLY: 'quarter',
  YEARLY: 'annual',
};

export const INSURANCE_PRODUCT_TYPES = {
  BUSINESS_LIABILITY: 'business_liability',
  COLLECTIVE_HEALTCARE: 'collective_healthcare',
  CYBER: 'cyber',
  DECENNIAL: 'decennial',
  HEALTHCARE: 'healthcare',
  IT_HARDWARE_DAMAGE: 'it_hardware_damage',
  KEY_PERSON: 'key_person',
  LEGAL_PROTECTION: 'legal_protection',
  LEGAL_SERVICE: 'legal_service',
  MOTOR_FLEET: 'motor_fleet',
  MULTI_RISK: 'multi_risk',
  OFFICE: 'office',
  PUBLIC_LIABILITY: 'public_liability',
  REVENUE_LOSS: 'revenue_loss',
};

export const REGISTRATION_DATE_THRESHOLD_MONTHS = 36;
