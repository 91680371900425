export const VALIDATION_ERRORS = {
  CONDITION_AMOUNT_EMPTY: 'conditions_empty',
  CONDITION_AMOUNT_LOWER: 'conditions_value_lower',
  CONDITION_AMOUNT_HIGHER: 'conditions_value_higher',
  CONDITION_AMOUNT_SAME: 'same_amount_in_conditions',
  INACTIVE_MEMBERSHIP: 'inactive_membership',
  MEMBERSHIP_NO_PERMISSION: 'membership_no_permission',
  MEMBERSHIP_NOT_ACTIVE: 'membership_not_active',
  MEMBERSHIP_NOT_FOUND: 'membership_not_found',
  NO_ACTIVE_USER_WITH_ROLE: 'no_active_user_with_role',
  STEP_APPROVERS_EMPTY: 'missing_key',
  STEP_OPERATION_EMPTY: 'missing_key',
  NO_PERMISSION_TO_REVIEW: 'no_permission_to_review',
  NO_ACTIVE_USERS: 'no_active_users',
  UNSUPPORTED_ROLE: 'unsupported_role',
};

export const APPROVAL_WORKFLOW_STATUSES = {
  AWAITS_MY_APPROVAL: 'awaits_my_approval',
  COMPLETED: 'completed',
};

export const OPERATION_TYPES = {
  ALL_OF: 'all_of',
  ONE_OF: 'one_of',
};

export const REQUEST_TYPES = {
  TRANSFER: 'transfer',
  REIMBURSEMENT: 'reimbursement',
  SUPPLIER_INVOICE: 'supplier_invoice',
};

export const APPROVAL_WORKFLOW_CONDITION_FIELDS = {
  AMOUNT: 'amount',
  SUPPLIER_ID: 'supplier_id',
  UPLOADER_ID: 'uploader_id',
};
