import { isTesting, macroCondition } from '@embroider/macros';

export const CENTS_MULTIPLIER = {
  BHD: 1000,
  BIF: 1,
  CLF: 10000,
  CLP: 1,
  CVE: 1,
  DJF: 1,
  GNF: 1,
  IQD: 1000,
  ISK: 1,
  JOD: 1000,
  JPY: 1,
  KMF: 1,
  KRW: 1,
  KWD: 1000,
  LYD: 1000,
  MGA: 10,
  MRO: 10,
  PYG: 1,
  RWF: 1,
  UGX: 1,
  UYI: 1,
  VND: 1,
  VUV: 1,
  XAF: 1,
} as const;

export const TRANSFER_ATTRS = [
  'name',
  'email',
  'iban',
  'accountNumber',
  'routingNumber',
  'accountType',
  'fx',
  'country',
  'activityTag',
  'sortCode',
  'bic',
] as const;

export const MULTI_TRANSFER_ATTRS = [
  'organization',
  'bankAccount',
  'initiator',
  'file',
  'status',
  'operationType',
  'scheduledDate',
  'activityTag',
  'scheduled',
  'limitsData',
] as const;

export const COMMON_TRANSFER_ATTRS = [
  'notifyByEmail',
  'activityTag',
  'note',
  'reference',
  'status',
  'amountCurrency',
  'operationType',
  'accountType',
  'country',
  'vatAmount',
  'vatRate',
  'amount',
  'scheduledDate',
  'organization',
  'bankAccount',
  'initiator',
  'labels',
  'name',
  'iban',
] as const;

export const BULK_TRANSFER_ATTRS = [
  'amountCurrency',
  'reference',
  'activityTag',
  'notifyByEmail',
  'selected',
] as const;

export const ERROR_GENERIC_CANCEL_ISSUE = 4000;
export const ERROR_CANNOT_CANCEL = 4001;
export const ERROR_ALREADY_CANCELED = 4002;

export const SEPA_MODAL_CLOSE_REASONS = {
  MAKE_NEW_TRANSFER: 'make_new_transfer',
  RETRY_AS_STANDARD: 'retry_as_standard',
  CONFIRM_TRANSFER: 'confirm_transfer',
} as const;

export const STANDARD_DECLINED_REASONS = {
  BENEFICIARY_ALREADY_USED_BY_ANOTHER: 'beneficiary_already_used_by_another',
  BENEFICIARY_BIC_INVALID: 'beneficiary_bic_invalid',
  BENEFICIARY_CANCELED: 'beneficiary_canceled',
  BENEFICIARY_IBAN_INVALID: 'beneficiary_iban_invalid',
  BENEFICIARY_NOT_SEPA: 'beneficiary_not_sepa',
  FX_BENEFICIARY_INVALID: 'fx_beneficiary_invalid',
  INCOHERENT_KYC_LEVEL: 'incoherent_kyc_level',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  INVALID_BENEFICIARY_DATA: 'invalid_beneficiary_data',
  KYC_LEVEL_REFUSED: 'kyc_level_refused',
  PROCESSING_ERROR: 'processing_error',
  USER_ASSETS_FROZEN: 'user_assets_frozen',
} as const;

export const INSTANT_DECLINED_REASONS = {
  ACCOUNT_UNREACHABLE: 'account_unreachable',
  INST_BENEFICIARY_INVALID: 'instant_beneficiary_invalid',
  CREDITOR_TIMEOUT: 'instant_creditor_timeout',
  DISPOSITION_ERROR: 'instant_disposition_error',
  FORMAT_REJECTED: 'instant_format_rejected',
  INST_BENEFICIARY_NOT_SEPA: 'instant_beneficiary_not_sepa',
  INST_PROCESSING_ERROR: 'instant_processing_error',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  OPERATIONAL_ERROR: 'instant_operational_error',
  PAYMENT_FAILED: 'instant_payment_failed',
  SCREENING_REJECTED: 'instant_screening_rejected',
} as const;

export const TRANSFER_DECLINED_REASONS = {
  ...STANDARD_DECLINED_REASONS,
  ...INSTANT_DECLINED_REASONS,
} as const;

export const INSTANT_DECLINED_REASONS_RETRY_STATUS = {
  [INSTANT_DECLINED_REASONS.ACCOUNT_UNREACHABLE]: false,
  [INSTANT_DECLINED_REASONS.INST_BENEFICIARY_INVALID]: true,
  [INSTANT_DECLINED_REASONS.CREDITOR_TIMEOUT]: true,
  [INSTANT_DECLINED_REASONS.DISPOSITION_ERROR]: true,
  [INSTANT_DECLINED_REASONS.FORMAT_REJECTED]: true,
  [INSTANT_DECLINED_REASONS.INST_BENEFICIARY_NOT_SEPA]: false,
  [INSTANT_DECLINED_REASONS.INST_PROCESSING_ERROR]: true,
  [INSTANT_DECLINED_REASONS.INSUFFICIENT_FUNDS]: false,
  [INSTANT_DECLINED_REASONS.OPERATIONAL_ERROR]: true,
  [INSTANT_DECLINED_REASONS.PAYMENT_FAILED]: true,
  [INSTANT_DECLINED_REASONS.SCREENING_REJECTED]: true,
};

export const STATUS = {
  ACCOUNT_CANCELED: 'account_canceled',
  BENEFICIARY_ACCOUNT_CANCELED: 'beneficiary_account_canceled',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  DECLINED: 'declined',
  KYB_ONHOLD: 'kyb_onhold',
  PENDING: 'pending',
  PENDING_REVIEW: 'pending_review',
  PENDING_SEIZURE: 'pending_seizure',
  PROCESSING: 'processing',
  VALIDATED: 'validated', // Kantox status, it mean validated from Kantox POV
  STANDING_PROCESSING: 'standing_processing',
  FINANCING_REQUESTED: 'financing_requested',
} as const;

export const PROCESSING_STATUS = [
  STATUS.KYB_ONHOLD,
  STATUS.PENDING,
  STATUS.PENDING_REVIEW,
  STATUS.PENDING_SEIZURE,
  STATUS.PROCESSING,
  STATUS.VALIDATED,
  STATUS.STANDING_PROCESSING,
  STATUS.FINANCING_REQUESTED,
] as const;

export const COMPLETED_STATUS = [
  STATUS.ACCOUNT_CANCELED,
  STATUS.BENEFICIARY_ACCOUNT_CANCELED,
  STATUS.CANCELED,
  STATUS.COMPLETED,
  STATUS.DECLINED,
] as const;

export const OPERATION_TYPES = {
  INTERNATIONAL_OUT: 'international_out',
  PAY_LATER: 'pay_later',
  SCHEDULED: 'scheduled',
  SEIZURE: 'seizure',
} as const;

export const SPEND_LIMIT_TYPES = {
  PER_TRANSFER: 'perTransfer',
  MONTHLY: 'monthly',
  RECURRING: 'recurring',
} as const;

export const SPEND_LIMIT_DISCLAIMER_TYPES = {
  INSUFFICENT_MONTHLY_LIMITS: 'insufficient_monthly_limits',
  INSUFFICENT_PER_TRANSFER_LIMITS: 'insufficient_per_transfer_limits',
  RECURRING_DECLINED: 'recurring_declined',
} as const;

export const INSTANT_DISCLAIMER_TYPES = {
  INSTANT_NON_REACHABLE_BIC: 'instant_non_reachable_bic',
  INSTANT_UNDER_MAINTENANCE: 'instant_under_maintenance',
  INSTANT_AMOUNT_ABOVE_THRESHOLD: 'instant_amount_above_threshold',
  INSTANT_OPERATIONAL_UNAVAILABILITY: 'instant_operational_unavailability',
  INSTANT_FUNCTIONAL_UNAVAILABILITY: 'instant_functional_unavailability',
  INSTANT_NOT_AVAILABLE_WITH_PAY_LATER: 'instant_no_pay_later',
} as const;

export const DISCLAIMER_TYPES = {
  ...SPEND_LIMIT_DISCLAIMER_TYPES,
  ...INSTANT_DISCLAIMER_TYPES,
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  COST: 'cost',
  BILLER_INSUFFICIENT_FUNDS: 'biller_insufficient_funds',
  ALREADY_ISSUED: 'already_issued',
  KYB_WAITING: 'kyb_waiting',
  KYC_WAITING: 'kyc_waiting',
  TOP_UP_REQUIRED: 'top_up_required',
  QONTO_BANK_ACCOUNT: 'qonto_bank_account',
} as const;

/**
 * Regex used to test a string agains the 52 countries listed in the SEPA schemes’ geographical scope.
 * The countries share sometimes the same country code.
 */
export const SEPA_COUNTRY_CODES_REGEX =
  /^(AD|AT|BE|BG|CH|CY|CZ|DE|DK|EE|ES|FI|FR|GB|GG|GI|GR|HR|HU|IE|IM|IS|IT|JE|LI|LT|LU|LV|MC|MT|NL|NO|PL|PT|RO|SE|SI|SK|SM|VA)/i;

export const SEPA_SCHEDULE_TYPES = ['scheduled', 'standing_weekly', 'standing_monthly'];

export const INSTANT_SETTLEMENT_MINIMAL_WAITING_TIME_MS = macroCondition(isTesting()) ? 500 : 2000;
export const INSTANT_SETTLEMENT_MAXIMAL_WAITING_TIME_MS = macroCondition(isTesting()) ? 600 : 10000;

export const PAY_BY_INVOICE_ANIMATION_IN_DURATION_MS = macroCondition(isTesting()) ? 500 : 4000;
export const PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN = macroCondition(isTesting()) ? 600 : 6000;

export const SEPA_TRACKING_TRANSFER_TYPE = {
  INSTANT: 'instant-sepa',
  BOOK_TRANSFER_SCHEDULED: 'book-transfer-scheduled',
  BOOK_TRANSFER: 'book-transfer',
  REGULAR_SEPA: 'regular-sepa',
  REGULAR_SEPA_SCHEDULED: 'regular-sepa-scheduled',
  REQUEST_FALLBACK: 'request-fallback',
} as const;

export const SEPA_TRACKING_SETTLEMENT_STATUS = {
  PENDING_KYBC: 'pending_KYBC',
  PENDING_REVIEW: 'pending_review',
  SUCCESS: 'success',
  TIMEOUT: 'timeout',
} as const;

export const SEPA_TRACKING_SETTLEMENT_CTA = {
  EXIT: 'exit',
  CLOSE: 'close',
  NEW_TRANSFER: 'new_transfer',
  SEE_REQUEST: 'see_request',
  FALLBACK: 'fallback_regular',
  TRANSFER_DETAILS: 'transfer_details',
} as const;

export const SEPA_TRACKING_SETTLEMENT_EVENTS = {
  LOADING_CLOSED: 'transfer-sepa_loading_closed',
  SUCCESS_CLOSED: 'transfer-sepa_success_closed',
  TIMEOUT_CLOSED: 'transfer-sepa_instant-timeout_closed',
  REJECT_CLOSED: 'transfer-sepa_reject_closed',
  FALLBACK_SUMMARY_CLOSED: 'transfer-sepa_fallback_summary_closed',
} as const;

export const TRANSFER_FLOW_ORIGIN = Object.freeze({
  EDIT: 'edit-scheduled-transfer',
  NEW_DEEPLINK: 'qonto.new',
  EMAIL_DEEPLINK: 'email',
  NRC: 'nrc',
  SUPPLIER_INVOICES: 'supplier-invoices',
  REPEAT_TRANSFER: 'repeat-transfer',
  PAY_LATER_COCKPIT: 'financing.pay-later',
  PAY_LATER_FLOW: 'pay-later-transfer',
  PAY_LATER_APP_SUCCESS: 'pay-later-application.success',
  OVERVIEW: 'overview.pay-later',
  QONTO_PILOT: 'qonto-pilot',
  PAY_BY_INVOICE: 'pay-by-invoice',
});

export const STRUCTURED_PAYMENT_PURPOSE_LIST = {
  //  JORDAN
  JO: {
    purpose_jordan_0101: 'transfers.purposes.jordan.pai',
    purpose_jordan_0102: 'transfers.purposes.jordan.pub',
    purpose_jordan_0109: 'transfers.purposes.jordan.sfa',
    purpose_jordan_0203: 'transfers.purposes.jordan.ppss',
    purpose_jordan_0404: 'transfers.purposes.jordan.travel',
    purpose_jordan_0602: 'transfers.purposes.jordan.export',
    purpose_jordan_0604: 'transfers.purposes.jordan.import',
    purpose_jordan_0801: 'transfers.purposes.jordan.tes',
    purpose_jordan_0802: 'transfers.purposes.jordan.fis',
    purpose_jordan_0803: 'transfers.purposes.jordan.its',
    purpose_jordan_0804: 'transfers.purposes.jordan.pcs',
    purpose_jordan_0805: 'transfers.purposes.jordan.cos',
    purpose_jordan_0806: 'transfers.purposes.jordan.rms',
    purpose_jordan_0807: 'transfers.purposes.jordan.mms',
    purpose_jordan_0808: 'transfers.purposes.jordan.mis',
    purpose_jordan_0809: 'transfers.purposes.jordan.mhs',
    purpose_jordan_0810: 'transfers.purposes.jordan.caes',
    purpose_jordan_0811: 'transfers.purposes.jordan.prpe',
    purpose_jordan_0812: 'transfers.purposes.jordan.pre',
  },
  // Bahrain
  BH: {
    purpose_bahrain_CHC: 'transfers.purposes.bahrain.chc',
    purpose_bahrain_FIS: 'transfers.purposes.bahrain.fis',
    purpose_bahrain_GDE: 'transfers.purposes.bahrain.gde',
    purpose_bahrain_IFS: 'transfers.purposes.bahrain.ifs',
    purpose_bahrain_ITS: 'transfers.purposes.bahrain.its',
    purpose_bahrain_PMS: 'transfers.purposes.bahrain.pms',
    purpose_bahrain_PPL: 'transfers.purposes.bahrain.ppl',
    purpose_bahrain_STR: 'transfers.purposes.bahrain.str',
    purpose_bahrain_TTS: 'transfers.purposes.bahrain.tts',
    purpose_bahrain_PRS: 'transfers.purposes.bahrain.prs',
    purpose_bahrain_TCS: 'transfers.purposes.bahrain.tcs',
    purpose_bahrain_RDS: 'transfers.purposes.bahrain.rds',
    purpose_bahrain_GMS: 'transfers.purposes.bahrain.gms',
    purpose_bahrain_SCO: 'transfers.purposes.bahrain.sco',
    purpose_bahrain_INS: 'transfers.purposes.bahrain.ins',
    purpose_bahrain_AFL: 'transfers.purposes.bahrain.afl',
  },
  // United Arab Emirates
  AE: {
    purpose_uae_AFA: 'transfers.purposes.uae.afa',
    purpose_uae_AFL: 'transfers.purposes.uae.afl',
    purpose_uae_CHC: 'transfers.purposes.uae.chc',
    purpose_uae_FIS: 'transfers.purposes.uae.fis',
    purpose_uae_GDE: 'transfers.purposes.uae.gde',
    purpose_uae_GRI: 'transfers.purposes.uae.gri',
    purpose_uae_GDI: 'transfers.purposes.uae.gdi',
    purpose_uae_PMS: 'transfers.purposes.uae.pms',
    purpose_uae_LLA: 'transfers.purposes.uae.lla',
    purpose_uae_LLL: 'transfers.purposes.uae.lll',
    purpose_uae_PPL: 'transfers.purposes.uae.ppl',
    purpose_uae_RNT: 'transfers.purposes.uae.rnt',
    purpose_uae_TCP: 'transfers.purposes.uae.tcp',
    purpose_uae_UTL: 'transfers.purposes.uae.utl',
    purpose_uae_STR: 'transfers.purposes.uae.str',
    purpose_uae_ITS: 'transfers.purposes.uae.its',
  },
  // China
  CN: {
    CGODDR: 'transfers.purposes.options.goods',
    CSTRDR: 'transfers.purposes.options.services',
    goods: 'transfers.purposes.options.goods',
    services: 'transfers.purposes.options.services',
  },
} as const;

export const PAYMENT_PURPOSE_TYPES = {
  NONE: 'none',
  STRUCTURED: 'structured',
  UNSTRUCTURED: 'unstructured',
} as const;
